import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

import {setCurrentUser} from "../../../redux/user/user.actions"


export const ProfileDropDown = (props:any) => {
  const {setCurrentUser, handle} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
       setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} variant="outlined" style={{backgroundColor: "rgba(33,33, 33, .9)"}}>
        {handle ? handle : "Account"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/account" onClick={()=>handleClose()}>Account</MenuItem>
        <MenuItem onClick={()=>{setCurrentUser(null);handleClose()}}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const mapState = (state: any) => ({
  handle: state.user.currentUser.handle

})


const mapDispatch = (dispatch: any) => ({
  setCurrentUser: (user:any) => dispatch(setCurrentUser(user))
})

export default connect(mapState, mapDispatch)(ProfileDropDown)