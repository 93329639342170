import { createSelector } from "reselect";
import { parseTimeString } from "./workout.utils";

export const getWorkoutStructure = (state) =>
  state.workout.currentWorkout.structure;
export const getCurrentMoveId = (state) => state.workout.currentMove;
export const getSelectedMoveId = (state) => state.workout.selectedMove;

export const getCurrentMoveInfo = createSelector(
  [getWorkoutStructure, getCurrentMoveId],
  (workout, { section, move }) => {
    return workout[section].moveList[move];
  }
);

export const getSelectedMoveInfo = createSelector(
  [getWorkoutStructure, getSelectedMoveId],
  (workout, { section, move }) => {
    return workout[section].moveList[move];
  }
);

export const getCurrentVideo = createSelector(
  [getCurrentMoveInfo, getCurrentMoveId],
  (move, { video }) => {
    return move.videos[video];
  }
);

export const getNextVideo = createSelector(
  [getCurrentMoveInfo, getCurrentMoveId],
  (move, { video }) => {
    return move.videos[video + 1] || null;
  }
);

export const getVideoInfo = (workoutStructure, { section, move, video }) => {
  return workoutStructure[section].moveList[move].videos[video];
};

export const getCurrentMoveDuration = createSelector(
  [getCurrentMoveInfo, getCurrentVideo],
  (move, currentVideo) => {
    if (currentVideo.duration) {
      return parseTimeString(currentVideo.duration);
    } else if (move.duration) {
      return parseTimeString(move.duration);
    } else {
      return getVideoInfo(currentVideo);
    }
  }
);
