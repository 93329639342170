import React from "react";
import "./CurrentMoveTimer.css";
import CustomButton from "../CustomButton/CustomButton.component";
import { fmtSec } from "../../Util/countdown.utility";
import { connect } from "react-redux";
import { toggleWorkoutStart } from "../../redux/workout/workout.actions";
import {
  getCurrentVideo,
  getCurrentMoveInfo,
} from "../../redux/workout/workout.selectors";

// import "@loadingio/loading-bar";

const CurrentMoveTimer = ({
  isStart,
  toggleWorkoutStart,
  moveName,
  currentTime,
  startTime,
  endTime,
  //duration = endTime-startTime
}) => {
  //this should only be aware of the current time, the start, and end time
  // console.log(`end time is ${endTime}`);
  // console.log(`start time is ${startTime}`);
  // console.log(`current time is ${currentTime}`);
  

  const duration = endTime - startTime;
  const timeSinceStart = currentTime - startTime;
  const percentageCompleted = (timeSinceStart / duration) * 100;
  const timeRemaining = endTime - currentTime;

  


  /* So what I want to do is on load figure out how to get the duration to be calculated off the start time then when it starts playing I want to switch that duration to be calculated off of the start time. I can't just use greater than or less than though because the current time will still be the previous video's current time */

  return (
    <div>
      <div className="container">
        <div className="progressbar-container">
          <div
            className="progressbar-complete"
            style={{
              width: `${percentageCompleted}%`,
            }}
          >
            {/* <div className="progressbar-liquid"></div> 
            ((currentMoveTime - duration) / currentMoveTime) * 100
            */}
          </div>
          <span className="text-container">
            <strong className="move-name">{moveName}</strong>
            <strong className="move-time">
              {fmtSec(Math.round(timeRemaining))}
            </strong>
          </span>
        </div>
      </div>
      <CustomButton
        onClick={() => {
          toggleWorkoutStart();
        }}
        size="large"
        style={{ marginLeft: 0, maxWidth: "300px" }}
      >
        {isStart && timeRemaining >= 0 ? "Pause" : "Start"}
      </CustomButton>
    </div>
  );
};

const mapState = (state) => ({
  isStart: state.workout.isStart,
  currentTime: state.workout.currentTime,
  startTime: getCurrentVideo(state).startTime,
  endTime: getCurrentVideo(state).endTime,
  moveName: getCurrentMoveInfo(state).moveName,
});

const mapDispatch = (dispatch) => ({
  toggleWorkoutStart: () => dispatch(toggleWorkoutStart()),
});

export default connect(mapState, mapDispatch)(CurrentMoveTimer);

/* 
Known Bugs: 
1. When you pause the timer at 0 sec the timeout still occurs and the next move is loaded up. Expected functionality is that the timeout is cleared when you press the pause button


*/
