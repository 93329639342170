/* TOC
1. Actual code
2. Template Code

*/


//1. Actual Code
export const testWorkout = {
  id: "1b164453-1953-4782-b38d-3a0d0242e42b",
  name: "Splits and One Arm Pull Up",
  difficulty: "Expert",
  equipment: {
    required: ["Pull-Up Bar"],
    optional: ["Blocks"],
  },
  description: `A stretching routine to get better leg extensions. Perform it before you do your dance routine so you can use the mobility that you need`,
  structure: [
    {sectionTitle: 'Stretching', moveList: [
      {
        id: "439d8608-ac94-4fc3-b0b3-a1259e6048ad",
        moveName: "Intro",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 0,
            endTime: 10,
            //name: 'Section 0 Move 0 video 0'
          },
        ],
        //other possible data fields
        //relatedMoves: null,
        //staticImg: 'URL' (For faster loading)
        //loop: true //for repeating twice
        //loopMultiplier: //for repeating X number of times
      },
      {
        id: "aa94394e-c9b6-4e82-8b63-f5286b81b770",
        moveName: "Grab The Floor",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 10,
            endTime: 35,
          }
        ],
      },
      {
        id: "2a3eae90-d437-4097-abd0-92b5ac3272b2",
        moveName: "Horse Stance With Twist",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 35,
            endTime: 64,
          }
        ],
      },
      {
        id: "11b4beb6-6bbe-4568-bfbe-5aefd9eea707",
        moveName: "Right Leg Bend Sequence",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 64,
            endTime: 103,
          }
        ],
      },
      {
        id: "32a39ad1-760f-4079-9218-e26e7d409192",
        moveName: "Right Leg Lunge Sequence",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 103,
            endTime: 189,
          }
        ],
      },
      {
        id: "634612c8-aef1-4162-a93a-4ba3166972e0",
        moveName: "Right Leg Kicks",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 189,
            endTime: 213,
          }
        ],
      },
      {
        id: "f360d4ae-8bfe-49b4-abb5-28396427690d",
        moveName: "Switch to Left Side",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 213,
            endTime: 220,
          }
        ],
      },
      {
        id: "b9479252-7da6-47ae-bb6e-c297426a0e4d",
        moveName: "Left Leg Bend Sequence",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 220,
            endTime: 256,
          }
        ],
      },
      {
        id: "a901e606-05eb-4d12-a823-2e25aed137d0",
        moveName: "Left Leg Lunge Sequence",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 256,
            endTime: 345,
          }
        ],
      },
      {
        id: "c6521b2b-5bcb-43f7-829a-f8b055031c00",
        moveName: "Left Leg Kicks",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 345,
            endTime: 362,
          }
        ],
      },
  ]},
  {sectionTitle:'One Arm Pull Ups', moveList: [
    {
      id: "f7fefd8a-10fc-4323-ac36-0a0e3cbd5ea2",
      moveName: "Section Intro",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=mclwRNmHYgU", 
          startTime: 51,
          endTime: 76,
        },
      ],
    },
    {
      id: "edd42415-9765-482d-b0fd-b393cc92c599",
      moveName: "One Arm Overhead Pull Up Motion",
      focus: 'squeeze your lats, at the bottom squeeze an orange between your arm pits, pull with the top of your arm to your lower back, squeeze your elbow to your hip, bring the hand to the chin, lift yourself up',
      videos: [
        {
          videoTitle: "",
          url: "https://youtu.be/wGrjCCkGSRA", 
          startTime: 57,
          endTime: 87,
          duration: 30
        },
      ],
    },
    {
      id: "b7b31f97-592c-4dbc-b1d6-b8da840d8c5b",
      moveName: "Jumping Jacks",
      focus: 'looking for light toes, heels stay off the ground the whole time',
      form: 'bring your hands over your heads and you jump the feet in and out, keep a slight bend in the arms and knees is allowed',
      duration: '0:30',
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=7ZNxd6ECYsQ", 
          startTime: 29,
          endTime: 59,
        },
      ],
    },
    {
      id: "61eb8114-802d-4da5-9845-fbef86a426d7",
      moveName: "Finger Pulses",
      variation: "Power",
      instructions: 'finger tips together, hands wide, pulse at a moderate beat (80bpm or aim for your HR), move until your ',
      duration: '0:30',
      videos: [
        {
          videoTitle: "",
          url: "https://youtu.be/wGrjCCkGSRA", 
          startTime: 131,
          endTime: 161,
        },
      ],
    },
    {
      id: "b81be7e3-8bf6-4a9b-995e-10b362ed64ab",
      moveName: "Grip Pulses",
      reason: 'Release tension from that previous stretch',
      instructions: 'squeeze your grip and relax',
      duration: [30,'s'],
      videos: [
        {
          videoTitle: "",
          url: "https://youtu.be/wGrjCCkGSRA", 
          startTime: 167,
          endTime: 197,
        },
      ],
    },
    {
      id: "e50973f3-427b-4129-87fc-5edda49820a5",
      moveName: "Active Pulls",
      duration: '0:30',
      formTip: "Focus on arms straight, then pull shoulder blades back and down, try to get the tips of them to touch the opposite pocket, feel the contraction between the shoulder blades",
      videos: [
        {
          videoTitle: "",
          url: "https://youtu.be/wGrjCCkGSRA", 
          startTime: 205,
          endTime: 235,
        },
      ],
    },{
      id: "332e21f3-b7a6-4229-b9c9-0f4b1e29360b",
      moveName: "Pull Ups - Warm Up Set",
      number: 8,
      duration: "<2minutes",
      type: "2 arm bodyweight only",
      purpose: 'to prime the movement and our muscles used to being under load',
      videos: [
        {
          videoTitle: "",
          url: "https://youtu.be/wGrjCCkGSRA", 
          startTime: 244,
          endTime: 260,
        },
      ],
    }, {
      id: "1ba2427c-82de-4b1f-b716-4f12c7fb4f6f",
      moveName: "Rest",
      duration: '1:00',
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=kK6HkqD5tgg",
          startTime: 0,
          endTime: 60,
        }
      ]
    },
    {
      id: "79a2a7f6-f547-4f8a-b004-963f356cb045",
      moveName: "Eccentric Pull ups",
      reps: 6,
      tempo: '3 seconds per',
      bothSides: true,
      sets: 3,
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=wGrjCCkGSRA", 
          startTime: 527,
          endTime: 542,
        },
      ],
    },
    {
      id: "cabe8a55-6a28-4ecb-acc7-20167fb1a861",
      moveName: "Rest",
      duration: '1:00',
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=kK6HkqD5tgg",
          startTime: 55,
          endTime: 120,
        }
      ]
    },
    {
      id: "9c97ac5d-29b7-46ea-a8f4-6776b1f356f7",
      moveName: "Eccentric Pull ups",
      reps: 6,
      tempo: '3 seconds per',
      bothSides: true,
      sets: 3,
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=wGrjCCkGSRA", 
          startTime: 527,
          endTime: 542,
        },
      ],
    },
    {
      id: "c4a7f974-dbbe-48dc-a392-33a501038832",
      moveName: "Rest",
      duration: '2:00',
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=kK6HkqD5tgg",
          startTime: 115,
          endTime: 240,
        }
      ]
    },
    {
      id: "deee8c3e-41c0-4130-9d72-6aaff1f9f5cb",
      moveName: "One arm Dead Hang Left",
      setType: 'burnout',
      timing: "20 seconds per side",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=wGrjCCkGSRA", 
          startTime: 949,
          endTime: 964,
        },
      ],
    },
    {
      id: "0f236678-a65c-4bc6-8e5f-ea8aab248184",
      moveName: "Hype",
      duration: '2:00',
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=ioNng23DkIM",
          startTime: 40,
          endTime: 57,
        }
      ]
    },
    {
      id: "50694edd-f786-4d48-b5af-f5ee0a1f5c0a",
      moveName: "One arm Dead Hang Right",
      setType: 'burnout',
      timing: "20 seconds per side",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=ioNng23DkIM", 
          startTime: 57,
          endTime: 72,
        },
      ],
    },
    {
      id: "5a615740-f618-4389-9fd4-cfa4c811e8eb",
      moveName: "Two arm Pullup Burnout",
      duration: '2:00',
      type: "motivation",
      videos: [
        {
          name: "David Goggins Pushups",
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=YU65JIE4MEQ",
          startTime: 137,
          endTime: 187,
        }
      ]
    },
    {
      id: "a1acd2d4-0451-4fd1-b435-00521fd75a30",
      moveName: "Pot Stirrers",
      bothSides: true,
      duration: [30, 's', 'per side'],
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=wGrjCCkGSRA", 
          startTime: 1053,
          endTime: 1116,
        },
      ],
    },
  ]},{sectionTitle:'Seated Wide Legs', moveList: [
    {
      id: "898e701c-63c0-4231-b55d-c7aef06f551e",
      moveName: "Wide Legs Right Overhead Reach",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 362,
          endTime: 389,
        },
      ],
    },{
      id: "a181e3b2-d593-480c-80fa-9e6cccf977c8",
      moveName: "Pancake Stretch - Right Leg",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 389,
          endTime: 410,
        },
      ],
    },{
      id: "7b230406-f867-4dd3-acdf-91c4b5d3bedd",
      moveName: "Wide Legs Left Overhead Reach",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 410,
          endTime: 429,
        },
      ],
    },
    {
      id: "b721feb2-11ba-4c08-a911-4fefa5966854",
      moveName: "Pancake Stretch - Left Leg",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 429,
          endTime: 450,
        },
      ],
    },
    {
      id: "083fc5a4-2485-4235-ba14-a0ffebf9e1f1",
      moveName: "Pancake Stretch - Left Leg",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 450,
          endTime: 480,
        },
      ],
    },
    {
      id: "24ff295f-db8d-4eb4-9a25-71b9b38b21fe",
      moveName: "Seated Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 480,
          endTime: 491,
        },
      ],
    },
    {
      id: "aac13f09-2fb9-42b3-88d1-4fac76d74fcc",
      moveName: "Butterfly Stretch - w/ Active Push",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 491,
          endTime: 516,
        },
      ],
    },
  ]},
  {sectionTitle:'Seated Forward Folds', moveList: [
    {
      id: "988ee75c-85b6-4a0f-a1fe-d1e47d0d57de",
      moveName: "Seated V-Fold",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 516,
          endTime: 536,
        },
      ],
    },
    {
      id: "1fa2ab94-0626-408d-b35f-267ad7fd88b5",
      moveName: "Seated Pike Stretch",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 536,
          endTime: 554,
        },
      ],
    },
  ]},
  {sectionTitle:'Supine Stretches', moveList: [
    {
      id: "fbcc228e-e807-414d-b54d-2ebca5dff5c9",
      moveName: "Supine Single Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 554,
          endTime: 571,
        },
      ],
    },{
      id: "853b9d39-e7d3-4b35-83f9-977a9368bdf1",
      moveName: "Supine Pigeon Stretch w/ Arm Thread",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 571,
          endTime: 589,
        },
      ],
    },{
      id: "64512ae3-c24e-4e4f-a7e5-a35291b4652f",
      moveName: "Supine Splits w/ Leg Bends",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 589,
          endTime: 639,
        },
      ],
    },
    {
      id: "2a24f57a-1f60-485c-bc23-7fda7b0d5ea7",
      moveName: "Supine Single Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 639,
          endTime: 653,
        },
      ],
    },{
      id: "00b20acc-9131-4aed-b9f7-bd064c39dad3",
      moveName: "Supine Pigeon Stretch w/ Thread",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 653,
          endTime: 666,
        },
      ],
    },{
      id: "8a95a876-95f6-46bf-9cfa-49f88c20b6ba",
      moveName: "Supine Splits w/ Leg Bends - Right",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 666,
          endTime: 704,
        },
      ],
    },
  ]},
  {sectionTitle:'Right Splits', moveList: [
    {
      id: "8a407974-bc33-4a04-bf9c-9ead6a0a9d4a",
      moveName: "Pigeon Pose",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 704,
          endTime: 730,
        },
      ],
    },{
      id: "e4675147-040c-4d86-b06b-5526670a4c38",
      moveName: "Calypso Stretch",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 730,
          endTime: 745,
        },
      ],
    },{
      id: "51f37e3b-6d5a-4185-94be-b2b9fae3cd7c",
      moveName: "Right Splits",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 745,
          endTime: 801,
        },
      ],
    },
  ]},
  {sectionTitle:'Left Splits', moveList: [
    {
      id: "353ae099-ea36-4ab2-b114-2a7973d27ae6",
      moveName: "Pigeon Pose",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 801,
          endTime: 830,
        },
      ],
    },{
      id: "3658a331-5b7b-416c-bc5e-29ed12f47dcf",
      moveName: "Calypso Stretch",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 830,
          endTime: 847,
        },
      ],
    },{
      id: "8099ed09-60ee-470f-84d4-0c6e39ecd658",
      moveName: "Left Splits",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 847,
          endTime: 900,
        },
      ],
    },
    {
      id: "1df1e114-9059-4664-8a37-a2805aef0ed9",
      moveName: "Seated Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 900,
          endTime: 914,
        },
      ],
    },
  ]},
  {sectionTitle:'Middle Splits', moveList: [
    {
      id: "5f7122c9-f186-49af-a17a-e9a519420836",
      moveName: "Middle Splits",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 914,
          endTime: 969,
        },
      ],
    },
    {
      id: "51a8cc22-0b2e-4fde-a231-c0f3abaf553d",
      moveName: "Seated Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 969,
          endTime: 981,
        },
      ],
    },
  ]},
  {sectionTitle:'Over Splits', moveList: [
    {
      id: "a7237cbc-464f-49eb-8f80-3f21102d7c5e",
      moveName: "Right Over splits",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 981,
          endTime: 1020,
        },
      ],
    },
    {
      id: "cceb758a-6a70-4f5c-b7f1-224d4400ae10",
      moveName: "Left Over Splits",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 981,
          endTime: 1020,
        },
      ],
    },
    {
      id: "d61dda48-7f8c-46dc-a57a-4b8fb0b13d18",
      moveName: "Middle Over Splits with Right Leg Elevated",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 981,
          endTime: 1020,
        },
      ],
    },
    {
      id: "5c67cf69-d487-4aa6-af1b-30908a5ec3f6",
      moveName: "Middle Over Splits with Left Leg Elevated",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 981,
          endTime: 1020,
        },
      ],
    },
    {
      id: "e8bdceda-7b4b-4903-b32e-fe289a1ff27b",
      moveName: "Seated Leg Hug",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 969,
          endTime: 981,
        },
      ],
    },
  ]},
  {sectionTitle:'Outro', moveList: [
    {
      id: "866b8378-8c98-453d-b7c8-a501045f173b",
      moveName: "Testimonials",
      videos: [
        {
          videoTitle: "",
          url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
          startTime: 1031,
          endTime: 1054,
        },
      ],
    },
  ]},
]
}


//2. Template Code
/* 
a. Example move
{sectionTitle:'Intro', moveList: [
      {
        id: "b12070b0-5bb3-482d-a583-5d7b46d6d93c",
        moveName: "Intro",
        videos: [
          {
            videoTitle: "",
            url: "https://www.youtube.com/watch?v=I6sgWVu3V2I", 
            startTime: 0,
            endTime: 10,
          },
        ],
        //other possible data fields
        //relatedMoves: null,
        //staticImg: ''
        //URL:'' (For faster loading)
        //loop: true //for repeating twice
        //loopMultiplier: //for repeating X number of times
      },
    ]},
*/