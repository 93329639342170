import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import WorkoutCardWrapper from "../../Components/WorkoutPreviewCard/WorkoutCard.HOC";
import { Container, Typography } from "@material-ui/core";
// import axios from "axios";

import { setFavoriteWorkouts } from "../../redux/user/user.actions";

// import {getFavoriteWorkouts, getCurrentUserProfile} from "../../Handlers/user.handlers"

import { getFavoriteWorkouts } from "../../Firebase/firebase.utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&>*": { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
    },
    profileContainer: {
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    paper: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(3),
    },
    avatarContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    avatar: {
      width: "150px",
      height: "150px",
    },
  })
);

interface AccountPageProps {
  displayName: string;
  profilePicture: string;
  handle: string;
  userToken: string;
  setFavoriteWorkouts: (workouts: any) => void;
  favoriteWorkouts: string[];
}

const AccountPage = ({
  displayName,
  profilePicture,
  handle,
  userToken,
  setFavoriteWorkouts,
  favoriteWorkouts,
}: AccountPageProps) => {
  /*
  So I want this to display the person's account information
  Also I'd like to allow them to edit their details by clicking on an edit icon and it changes to an inline editor box


  Info I'd Like To Display
  1. Account name
  2. Account picture
  3. Account handle
  4. Their favorite workouts
  5. The email they used to sign up
  
  
  */

  //ACCOUNT TEST CONSTANT
  // const displayName = "Jacob Haugen";
  // const profilePicture =
  //   "https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80";
  // const handle = "@jake";

  useEffect(() => {
    const initFavorites = async () => {
      setFavoriteWorkouts(await getFavoriteWorkouts());
    };
    initFavorites();
    // let cancel: () => void;
    // const CancelToken = axios.CancelToken;
    // axios
    //   .get("http://localhost:5000/waterstone-98dcd/us-central1/api/favorites", {
    //     headers: { Authorization: `Bearer ${userToken}` },
    //     cancelToken: new CancelToken(function executor(c: () => void) {
    //       // An executor function receives a cancel function as a parameter
    //       cancel = c;
    //     }),
    //   })
    //   .then((res) => res.data)
    //   .then((favWorkouts: object[]) => {
    //     let favMap = new Map<string, object>();
    //     favWorkouts.forEach((workout: any) =>
    //       favMap.set(workout.workoutId, workout)
    //     );
    //     setFavoriteWorkouts(favMap);
    //   });
    // return () => {
    //   cancel();
    // };
  }, [userToken, setFavoriteWorkouts]);

  //get the workout card to display the differnent workouts

  const classes = useStyles();

  //Figure out if the user has favorite workouts and then if so convert the Map of favorites to JSX
  let favWorkoutGrid: any;
  if (favoriteWorkouts) {
    // console.log(favoriteWorkouts)
    let mappedWorkouts: any[] = [];
    favoriteWorkouts.forEach((workoutId: string) => {
      mappedWorkouts.push(
        <Grid item key={workoutId} xs={12} sm={6} md={4}>
          <WorkoutCardWrapper workoutId={workoutId} />
        </Grid>
      );
    });
    favWorkoutGrid = mappedWorkouts;
  } else {
    favWorkoutGrid = (
      <Typography variant="h4">
        You do not have any favorited workouts yet
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Typography variant="h2">Account Information</Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className={classes.profileContainer}
      >
        <Paper className={classes.paper}>
          <Grid xs={12} sm={6} item className={classes.avatarContainer}>
            <Avatar
              src={profilePicture}
              alt="User's Thumbnail"
              className={classes.avatar}
            />
            <h2>Profile Picture</h2>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Typography variant="h4">
              Name: <span>{displayName}</span>
            </Typography>
            <Typography variant="h4">
              Username: <span>@{handle}</span>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
      <Typography variant="h2">Favorite Workouts</Typography>
      <Grid
        container
        direction="row"
        spacing={3}
        justify="center"
        alignItems="flex-start"
      >
        {favWorkoutGrid}
        {/* Would ideally like to just map through the users favorite workouts and display a card for each of them
            something like
            favoriteWorkouts.map((workout)=>{
              <WorkoutCard workout={workout}/>
            }) 
          
          */}
      </Grid>
    </Container>
  );
};

const mapState = (state: any) => ({
  displayName: state.user.currentUser.name,
  profilePicture: state.user.currentUser.profilePicture,
  handle: state.user.currentUser.handle,
  favoriteWorkouts: state.user.favoriteWorkouts,
});

const mapDispatch = (dispatch: any) => ({
  setFavoriteWorkouts: (workouts: string[]) =>
    dispatch(setFavoriteWorkouts(workouts)),
});

export default connect(mapState, mapDispatch)(AccountPage);
