const WorkoutActionTypes = {
  SET_CURRENT_WORKOUT: "SET_CURRENT_WORKOUT",
  TOGGLE_IS_START: "TOGGLE_IS_START",
  SET_CURRENT_MOVE_ID: "SET_CURRENT_MOVE_ID",
  SET_SELECTED_MOVE_ID: "SET_SELECTED_MOVE_ID",
  SET_NEXT_VIDEO_ID: "SET_NEXT_VIDEO_ID",
  SET_CURRENT_TIME: "SET_CURRENT_TIME",
  PLAY_WORKOUT: "PLAY_WORKOUT",
  PAUSE_WORKOUT: "PAUSE_WORKOUT",
  SET_VIDEO_REF: "SET_VIDEO_REF",
  SET_MOVE_REF: "SET_MOVE_REF",
  DISPLAY_MOVE_INFO_PANEL: "DISPLAY_MOVE_INFO_PANEL",
  UPDATE_GLOBAL_TIMER: "UPDATE_GLOBAL_TIMER"
}

export default WorkoutActionTypes