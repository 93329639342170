import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import workoutReducer from "./workout/workout.reducer"
import userReducer from "./user/user.reducer"

// const MapTransform = createTransform(
//   // transform state on its way to being serialized and persisted.
//   (inboundState, key) => {
//     // convert myMap to an Array.
//     console.log(...inboundState.favoriteWorkouts)
//     return { ...inboundState, favoriteWorkouts: [...inboundState.favoriteWorkouts] };
//   },
//   // transform state being rehydrated
//   (outboundState, key) => {
//     // convert myMap back to a Map.
//     return { ...outboundState, favoriteWorkouts: new Map(outboundState.favoriteWorkouts) };
//   },
//   // define which reducers this transform gets called for.
//   { whitelist: ['user'] }
// );

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["user"],
  // transforms: [MapTransform]
}

const rootReducer = combineReducers({
  workout: workoutReducer,
  user: userReducer
});

export default persistReducer(persistConfig, rootReducer)