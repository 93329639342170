import React, { useRef, useEffect } from "react";
import "./MoveListItem.styles.scss";
import { connect } from "react-redux";
import {
  setCurrentMove,
  setSelectedMove,
  displayMoveInfoPanel,
  setMoveRef
} from "../../../redux/workout/workout.actions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";



//setCurrentMove is coming from redux, other props are passed in from MoveList
const MoveListItem = ({
  movePositionId,
  isCurrentMove,
  moveName,
  time,
  setCurrentMove,
  setMoveRef
}) => {
  const moveItemRef = useRef(null);
  useEffect(() => {
    if (moveItemRef.current !== null && isCurrentMove) {
      setMoveRef(moveItemRef.current);
      // console.log(moveItemRef.current)
    }

    return () => {
      if (isCurrentMove) {
        setMoveRef(null);
      }
    };
  }, [setMoveRef, moveItemRef, isCurrentMove]);
  return (
    <div
      className={`${isCurrentMove ? "current-move" : ""} move-item`}
      ref={moveItemRef}
    >
      <span
        className="move-text"
        onClick={() => {
          setCurrentMove(movePositionId);
          moveItemRef.current.scrollIntoView({ behavior: "smooth" });
        }}
      >
        {moveName} - {time}s
      </span>
      {/* <div className="move-info-btn" onClick={() => {
        setSelectedMove(movePositionId)
        displayMoveInfoPanel(true)
        return null
        }}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </div> */}
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  setCurrentMove: (id) => dispatch(setCurrentMove(id)),
  setSelectedMove: (id) => dispatch(setSelectedMove(id)),
  displayMoveInfoPanel: (control) => dispatch(displayMoveInfoPanel(control)),
  setMoveRef: (ref) => dispatch(setMoveRef(ref)),
});

export default connect(null, mapDispatch)(MoveListItem);
