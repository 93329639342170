import React from "react";

import IconButton from "@material-ui/core/IconButton";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Tooltip from "@material-ui/core/Tooltip";

const ContactButton = () => {
  return (
    <Tooltip
      title="Report a bug, request a feature, or just say thanks"
      aria-label="Send us an email"
    >
      <IconButton href="mailto:jake@waterstonefitness.com">
        <ContactSupportIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ContactButton;
