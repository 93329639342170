import React, { useState } from "react";

import { Button } from "@material-ui/core";
import FormikVideoEditor from "../FormikVideoEditor/FormikVideoEditor.component";

const VideoDetailsList = ({ videos }) => {
  const [editorIndex, setEditorIndex] = useState(null);
  return (
    <div>
      {videos.map((video, videoIdx) => {
        if (editorIndex === videoIdx) {
          return (
            <FormikVideoEditor
              key={`${video.videoTitle} - ${videoIdx}`}
              video={video}
              videoIdx={videoIdx}
              setEditorIndex={setEditorIndex}
            />
          );
        } else {
          return (
            <div key={`${video.videoTitle} - ${videoIdx}`}>
              <h3>Video Title: {video.videoTitle || "No Title"}</h3>
              <h3>Video URL: {video.url}</h3>
              <h3>Start Time: {video.startTime}</h3>
              <h3>End Time: {video.endTime}</h3>
              <Button onClick={() => setEditorIndex(videoIdx)}>Edit</Button>;
            </div>
          );
        }
      })}
    </div>
  );
};

export default VideoDetailsList;
