const USER_TYPES = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_USER_AUTH: "SET_USER_AUTH",
  SET_USER_TOKEN:"SET_USER_TOKEN",
  SET_FAVORITE_WORKOUTS: "SET_FAVORITE_WORKOUTS",
  DELETE_FAVORITE_WORKOUT: "DELETE_FAVORITE_WORKOUT",
  ADD_FAVORITE_WORKOUT: "ADD_FAVORITE_WORKOUT",
  FETCH_FAVORITES_START:"FETCH_FAVORITES_START",
  FETCH_FAVORITES_SUCCESS:"FETCH_FAVORITES_SUCCESS",
  FETCH_FAVORITES_FAILURE:"FETCH_FAVORITES_FAILURE"
}

export default USER_TYPES