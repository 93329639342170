import React, { useState, useEffect } from "react";
import { getWorkoutById } from "../../Firebase/firebase.utils";
import WorkoutPreviewCard from "./WorkoutPreviewCard.component"
import CircularProgress from "@material-ui/core/CircularProgress"

const WorkoutCardWrapper = ({workoutId}) => {
  //fetch the workout with that Id from firebase
  const [workoutData, setWorkoutData] = useState(null);
  useEffect(() => {
    const fetchWorkoutData = async (id) => {
      const fetchedWorkout = await getWorkoutById(id);
      console.log(`Workout Id: ${id}`)
      console.log(fetchedWorkout)
      setWorkoutData(fetchedWorkout)
    };
    fetchWorkoutData(workoutId)
  }, [workoutId]);

  return (
    //display either the workout preview card with the fetched data or a skeleton card
    <div>
      {workoutData ? <WorkoutPreviewCard workout={workoutData} workoutId={workoutId}/>: <CircularProgress />}
    </div>
  );
};

export default WorkoutCardWrapper;
