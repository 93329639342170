import React from 'react'

import "./WorkoutCategories.styles.scss"

import CategoryCard from "../CategoryCard/CategoryCard.component"

const WORKOUTS_CARDS_LIST = [
  {
    id: 0,
    title: "Basketball Skills",
    duration:"30 min",
    difficulty: "Beginner",
    imageUrl: 'https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed07bdbfb3e476075d55595_Basketball_workout.png',
    linkUrl: 'w/Ks4qyv3CKK2vAVM5EXUh'
  },
  {
    id: 1,
    title: "Chest and Back",
    duration:"1 hr",
    difficulty: "Expert",
    imageUrl: 'https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed07bdb14a9887986990018_Lifting%20Weights.png',
    linkUrl: 'w/FVh515ue8hZFF5QaTd6E'
  },
  // {
  //   id: 2,
  //   title: "Maximum Pump",
  //   duration:"25 min",
  //   difficulty: "Intermediate",
  //   imageUrl: 'https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed07bdb14cfa86972b4a1f3_Flexing%20-%20Test%20Workout.png',
  //   linkUrl: 'maximum-pump'
  // },
  // {
  //   id: 3,
  //   title: "Sprinter Speed",
  //   duration:"45 min",
  //   difficulty: "Expert",
  //   imageUrl: 'https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed07bdc3ade775eaaa6bc1c_Sprinting%20-%20Test%20Workout.png',
  //   linkUrl: 'sprinter-speed'
  // },
  // {
  //   id: 4,
  //   title: "Yoga Strength",
  //   duration:"45 min",
  //   difficulty: "Beginner",
  //   imageUrl: 'https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed07bdcc82d07d987df4340_Yoga%20-%20Test%20Workout.png',
  //   linkUrl: 'yoga-strength'
  // },
]

const WorkoutCategories = () => {
  const sections = WORKOUTS_CARDS_LIST
  return (
    <div className="categories-menu">
      {sections.map((section)=><CategoryCard key={section.id} {...section}/>)}
    </div>
  )
}

export default WorkoutCategories
