import firebase from "firebase/app";
// Add the Firebase products that you want to use;
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAz5-vrXxLv-bxGTYTYky7Et2QBaPZRuW4",
  authDomain: "waterstone-98dcd.firebaseapp.com",
  databaseURL: "https://waterstone-98dcd.firebaseio.com",
  projectId: "waterstone-98dcd",
  storageBucket: "waterstone-98dcd.appspot.com",
  messagingSenderId: "638979583363",
  appId: "1:638979583363:web:3a23710f2736ca45fc1ab6",
  measurementId: "G-887ND8CG25",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`/users/${userAuth.uid}`); //will want to change this to UID later
  const docSnapshot = await userRef.get();
  // console.log(docSnapshot)
  //creates new user if there is no existing user with that UID
  if (!docSnapshot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.error(`Error creating new user: ${error.message}`);
    }
  } else {
    // console.log('doc snapshot exists')
    // console.log(docSnapshot.data())
  }

  return userRef;
};

export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      // console.log(user)
      if (user) {
        user.getIdToken().then(
          (idToken) => {
            resolve(idToken);
          },
          (error) => {
            reject(null);
          }
        );
      } else {
        reject("User does not exist");
      }
    });
  });
};

export const handleFavoriteAsync = async (workoutAction, workoutId) => {
  // console.log(`Handle Favorite Workout Called. Workout Action: ${workoutAction} | WorkoutId: ${workoutId}`);
  if (!auth.currentUser) {
    console.error("You need an account to favorite a workout");
    return "You need an account to favorite a workout";
  }
  // console.log(`current User ID: ${auth.currentUser.uid}`)
  if (!workoutId) {
    console.error("You need to provide a workout Id");
    return;
  }
  if (workoutAction === "ADD") {
    return await addFavoriteWorkout(workoutId);
  } else if (workoutAction === "DELETE") {
    return await deleteFavoriteWorkout(workoutId);
  } else {
    console.error("You need to have a valid method");
  }
};

const addFavoriteWorkout = async (workoutId) => {
  //This should already be prevalidated from handleFavoriteAsync call
  // console.log("addFavorite Called")
  try {
    const workoutSnapShot = await firestore.doc(`workouts/${workoutId}`).get();
    if (!workoutSnapShot.exists) {
      return console.error(`Workout ${workoutId} doesn't exist`);
    }
    const workoutData = workoutSnapShot.data();
    const workoutDataToAddToFavorites = {
      name: workoutData.name,
      thumbnailUrl: workoutData.thumbnailUrl || "",
      favoritedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    const setPromise = await firestore
      .doc(`/users/${auth.currentUser.uid}/favorites/${workoutId}`)
      .set(workoutDataToAddToFavorites);
    // alert(`Workout ${workoutId} successfully saved to ${auth.currentUser.uid}'s account`)
    return setPromise;
  } catch (err) {
    console.error(err);
    console.error(
      `Couldn't save ${workoutId} to ${auth.currentUser.uid}'s account`
    );
  }
};

const deleteFavoriteWorkout = async (workoutId) => {
  // console.log("Delete favorite workout called")
  try {
    //get the doc ref
    const deleteRef = firestore.doc(
      `/users/${auth.currentUser.uid}/favorites/${workoutId}`
    );
    //send delete command
    const deletePromise = deleteRef.delete();
    //return success
    // alert(`Workout ${workoutId} successfully unfavorited from ${auth.currentUser.uid}'s account`)
    return deletePromise;
  } catch (err) {
    console.error(err);
    alert(
      `Couldn't unfavorite ${workoutId} from ${auth.currentUser.uid}'s account`
    );
  }
};

export const getFavoriteWorkouts = async () => {
  //get ref to current user's favorites
  //needs to return the new Map otherwise app will fail on load because undefined will be loaded in as favorite workouts
  if (!auth.currentUser) {
    Promise.reject("You must be logged in to get favorites");
    return [];
  }
  try {
    const usersFavoritesSnapshot = await firestore
      .collection(`users/${auth.currentUser.uid}/favorites`)
      .get();
    //Goal: {'workoutId': true}
    const usersFavoriteWorkouts = [];
    //get the data from that whole collection
    usersFavoritesSnapshot.docs.forEach((doc) =>
      usersFavoriteWorkouts.push(doc.id)
    );
    //return that data
    return Promise.resolve(usersFavoriteWorkouts);
  } catch (err) {
    console.error(err);
    Promise.reject("Couldn't retreive favorites");
  }
};

export const getWorkoutById = async (id) => {
  // console.log(`getWorkoutById called with ${id} id`)
  const workoutRef = firestore.doc(`/workouts/${id}`);
  // console.log(workoutRef)
  const workoutDoc = await workoutRef.get();
  // console.log(workoutDoc.data())
  return workoutDoc.data();
};

export default firebase;
