import React from "react";
import {connect} from "react-redux"

import CustomInput from "../CustomInput/CustomInput.component";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";

import { videoValidationSchema } from "../../Util/validation.utility";
import update from 'immutability-helper';

import {setCurrentWorkout} from "../../redux/workout/workout.actions"

//Idea is that you click submit on this form and it updates the video in the workout
const FormikVideoEditor = ({ video, videoIdx, setEditorIndex, selectedMove, currentWorkout, JWT, setCurrentWorkout }) => {

  const updateWorkout = async (workout, path, data) => {
    const {section, move, video} = path
    // const pathToData = `structure[${section}].moveList[${move}].videos[${video}]`
    
    let updatedWorkout = update(workout, {
      structure: {[section]: {moveList: {[move]: {videos: {[video]: {$set: data}}}}}},
    });
    //need to get the correct video to update in the workoutData
    //then just set that value to be the corresponding data
    const requestOptions = {
      method: "PATCH",
      headers: {'Content-Type': 'application/json', Authorization: `Bearer ${JWT}`},
      body: JSON.stringify(updatedWorkout, null, 2)
    }

    let res = await fetch(`https://us-central1-waterstone-98dcd.cloudfunctions.net/api/workout/update/${updatedWorkout.workoutId}`, requestOptions)
    // console.log(res.status)
    if (res.status === 200) {
      setCurrentWorkout(updatedWorkout)
    }
    return res.json()
  }

  

  return (
    <div>
      <Formik
        initialValues={video}
        enableReinitialize={true}
        validationSchema={videoValidationSchema}
        onSubmit={async (values) => {
          let currentVideoPath = {video: videoIdx, ...selectedMove}
          let response = await updateWorkout(currentWorkout, currentVideoPath, values)
          setEditorIndex(null)
          alert(response)
          // await new Promise((r) => setTimeout(r, 500));
          // alert(JSON.stringify(values, null, 2));
          
        }}
      >
        <Form>
          <CustomInput
            label="Video Title:"
            name="videoTitle"
            type="text"
          ></CustomInput>
          <CustomInput label="URl: " name="url" type="text"></CustomInput>
          <CustomInput
            label="Start Time: "
            name="startTime"
            type="number"
          ></CustomInput>
          <CustomInput
            label="End Time:"
            name="endTime"
            type="number"
          ></CustomInput>
          <Button variant="contained" type="submit">
            Save
          </Button>
          <Button variant="contained" type="cancel" onClick={()=>setEditorIndex(null)}>
            Cancel
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

const mapState = state => ({
  selectedMove: state.workout.selectedMove,
  currentWorkout: state.workout.currentWorkout,
  JWT: state.user.currentUser.token
})

const mapDispatch = dispatch => ({
  setCurrentWorkout: workout => dispatch(setCurrentWorkout(workout))
})


export default connect(mapState, mapDispatch)(FormikVideoEditor);
