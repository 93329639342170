//import dependencies
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute.hoc";
import { connect } from "react-redux";
import Particles from "react-particles-js";

import WorkoutPage from "./Pages/WorkoutPage/Workout.page";
import WorkoutConstructor from "./Pages/WorkoutConstructor/WorkoutConstructor.page";
// import BrowseWorkouts from "./Pages/BrowseWorkouts/BrowseWorkouts.page";
import Header from "./Components/Header/Header.component";
import MainPage from "./Pages/MainPage/MainPage.component";
import SignInAndSignUp from "./Pages/SignInAndSignUp/SignInAndSignUp.page";
// import FavoriteWorkouts from "./Pages/FavoriteWorkouts/FavoriteWorkouts.page";
import AccountPage from "./Pages/AccountPage/AccountPage.page";

import "./App.css";
//currently tested component
// import FavoriteButton from "./Components/FavoriteButton/FavoriteButton.component";
import { setFavoriteWorkouts, setCurrentUser } from "./redux/user/user.actions";
import {auth, getFavoriteWorkouts} from "./Firebase/firebase.utils"
// import { setFavoriteWorkout } from "./redux/user/user.handlers";

const ParticleOptions = {
  particles: {
    number: {
      value: 500,
      density: {
        enable: true,
        value_area: 1500,
      },
    },
    line_linked: {
      enable: true,
      opacity: 0.02,
    },
    move: {
      direction: "right",
      speed: 0.05,
    },
    size: {
      value: 1,
    },
    opacity: {
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0.05,
      },
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      push: {
        particles_nb: 1,
      },
    },
  },
  retina_detect: true,
};

//this is a sample array that I'll eventually be pulling from my server
//KNOWN BUG if you put in a time of 0 it will end the timer prematurely, this is by design in the movetimer app,

function App({ currentUser, setFavoriteWorkouts }) {
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        setCurrentUser(userAuth);
        getFavoriteWorkouts().then(userFavorites=>setFavoriteWorkouts(userFavorites))
        
      }
    })
    return unsubscribeFromAuth
  }, [setFavoriteWorkouts])

  return (
    <div className="App">
      <div className="background-img" />
      <Particles className="Particles" params={ParticleOptions} />
      <Header />
      {/* THis needs to go inside of  function that can call dispatch with it, */}
      {/* <button onClick={()=>getFavoriteWorkouts()}>CLICK ME TO TEST FAVORITE WORKOUTS</button> */}
      <Switch>
        <Route path="/" exact component={MainPage} />
        {/* <Route path="/test">
          <FavoriteButton workoutId="9Z4N1YcfsqrYCrMsAq7Y" />
        </Route> */}
        <Route exact path="/w/:id" component={WorkoutPage} />
        <ProtectedRoute
          exact
          path="/w/:id/edit"
          component={WorkoutConstructor}
        />
        <ProtectedRoute path="/create" component={WorkoutConstructor} />
        {/* <ProtectedRoute path="/favorites" component={FavoriteWorkouts} /> */}
        <ProtectedRoute path="/account" component={AccountPage} />
        <Route
          exact
          path="/signin"
          render={() =>
            currentUser ? <Redirect to="/" /> : <SignInAndSignUp />
          }
        />
        {/* <Route path="/browse" component={BrowseWorkouts} /> */}
        {/* <Route path="/test" component={FieldArrayTest} /> */}
        {/* <Route exact path="/video" component={VideoPlayer} />
        
        
        <
        
        {/*The workouts tab should be to the indidual workouts and then the main app should launch as a subpage within that for SEO purposes (think headspace - url structure) /}
        */}
      </Switch>
    </div>
  );
}

const mapState = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatch = (dispatch) => ({
  setFavoriteWorkouts: (favorites)=>dispatch(setFavoriteWorkouts(favorites)),
})

export default connect(mapState, mapDispatch)(App);

/*
List of features:
-Keep multiple timers in sync
-progress bar on the timer
-scrollable progress bar
-fetch workouts
-backend server to generate workout
-multiple pages

*/
