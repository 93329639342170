import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { cyan, grey } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteButton from "../FavoriteButton/FavoriteButton.component";
import Button from "@material-ui/core/Button";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import Avatar from "@material-ui/core/Avatar";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: grey[900],
      color: "white",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    avatar: {
      backgroundColor: cyan[500],
    },
    cardDetails: {
      color: "white",
    },
    cardBottomButtons: {
      justifyContent: "space-between",
      "&>button": { margin: "12px" },
    },
  })
);

//eventually would like to open this up to a netflix like preview of the workout with the details and I want to have it's own page for the components. Look at netflix redesigns

// const sampleProps = {
//   workoutId: "ReuwBjFOyIatxuVXNc3D",
//   workoutTitle: "Core Workout",
//   duration: "30:00",
//   imageUrl:
//     "https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
//   description:
//     "You can (and should) scale the workouts to fit your level, but you should never scale so much that you don't feel challenged. Every workout should feel difficult. Your heart should be racing and you should be sweating. Push yourself as much as you can. Rest when you must, but don't take more than a minute or two to catch your breath.",
// };

interface WorkoutPreviewCardProps {
  
  workoutTitle: string;
  duration: string;
  imageUrl: string;
  description: string;
  name: string
  favorite: boolean;
}

const WorkoutPreviewCard = ({ workout, workoutId }: any) => {
  const {
    workoutTitle,
    name,
    duration,
    imageUrl,
    description,
  }: WorkoutPreviewCardProps = workout;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardDetails}
        title={workoutTitle || name}
        titleTypographyProps={{ variant: "h6" }}
        subheader={duration}
        subheaderTypographyProps={{ className: classes.cardDetails }}
      />
      <CardMedia
        className={classes.media}
        image={imageUrl || "https://images.unsplash.com/photo-1521805103424-d8f8430e8933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"}
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" noWrap component="p" align="left">
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardBottomButtons}>
        <div>
          <FavoriteButton workoutId={workoutId} />
          <IconButton
            aria-label="share"
            className={classes.cardDetails}
            onClick={async () => {
              try { 
                await navigator.clipboard.writeText(
                  `https://waterstone.app/w/${workoutId}`
                );
                alert(`"https://waterstone.app/w/${workoutId}" copied to clipboard`)
              } catch (err) {
                alert(err);
              }
            }}
          >
            <ShareIcon />
          </IconButton>
        </div>
        <Button variant="outlined" color="inherit" href={`/w/${workoutId}`}>
          Play
        </Button>
      </CardActions>
    </Card>
  );
};

export default WorkoutPreviewCard;
