import WorkoutActionTypes from "./workout.types"

export const setCurrentWorkout = workout => ({
  type: WorkoutActionTypes.SET_CURRENT_WORKOUT,
  payload: workout
})

export const toggleWorkoutStart = () => ({
  type: WorkoutActionTypes.TOGGLE_IS_START
})

export const playWorkout = () => ({
  type: WorkoutActionTypes.PLAY_WORKOUT
})

export const pauseWorkout = () => ({
  type: WorkoutActionTypes.PAUSE_WORKOUT
})

export const setCurrentMove = (id) => ({
  type: WorkoutActionTypes.SET_CURRENT_MOVE_ID,
  payload: id
})

export const setNextVideo = () => ({
  type: WorkoutActionTypes.SET_NEXT_VIDEO_ID
})

export const setCurrentTime = (time) => ({
  type: WorkoutActionTypes.SET_CURRENT_TIME,
  payload: time
})

export const setVideoRef = (ref) => ({
  type: WorkoutActionTypes.SET_VIDEO_REF,
  payload: ref
})

export const setMoveRef = (ref) => ({
  type: WorkoutActionTypes.SET_MOVE_REF,
  payload: ref
})

export const displayMoveInfoPanel = (state) => ({
  type: WorkoutActionTypes.DISPLAY_MOVE_INFO_PANEL,
  payload: state
})

export const updateGlobalTimer = (timerState) => ({
  type: WorkoutActionTypes.UPDATE_GLOBAL_TIMER,
  payload: timerState
})

export const setSelectedMove = (id) => ({
  type: WorkoutActionTypes.SET_SELECTED_MOVE_ID,
  payload: id
})

