//utils to advance through the workout

export const getNextVideoID = (sectionArr, currentMoveID) => {
  // console.log('getNextVideoId Invoked')
  // console.log(currentMoveID)
  const { section: sectionIdx, move: moveIdx, video: videoIdx } = currentMoveID;
  // let sectionIdx = 1;
  // let moveIdx = 3;
  // let videoIdx = 0;
  const currentMoveArr = sectionArr[sectionIdx].moveList;
  const currentVideoArr = currentMoveArr[moveIdx].videos;
  try {
    if (videoIdx !== currentVideoArr.length - 1) {
      //if there's another video in the array return that
      // console.log("getting next video");
      // console.log(currentVideoArr[videoIdx + 1]);
      return { ...currentMoveID, video: videoIdx + 1 };
    } else if (moveIdx !== currentMoveArr.length - 1) {
      //if there's not another video then check if we're at the end of the section
      // console.log("Getting next move");
      //console.log(currentMoveArr[moveIdx + 1].videos[0]); //if not then grab the next move's first video
      return { ...currentMoveID, move: moveIdx + 1, video: 0 };
    } else if (sectionIdx !== sectionArr.length - 1) {
      //if we are then check if we're at the end of the workout
      //console.log("getting next section");
      //console.log(sectionArr[sectionIdx + 1].moveList[0].videos[0]); //if not then grab the next section's first video
      return { ...currentMoveID, section: sectionIdx + 1, move: 0, video: 0 };
    } else {
      //console.log("end of the workout"); //this should mean that we have no more sections, moves, or videos to watch and are at the end of the workout
      return {...currentMoveArr, section: 0, move: 0, video: 0} //for now restart to the beginning
    }
  } catch (error) {
    console.warn(`Error no video found`);
    //if there is no section or video in the next workout section then console.error a message of no video found in the next section
    return {...currentMoveArr, section: 0, move: 0, video: 0} //then grab the first video again
  }
}; //feels like their might be a recursive solution


export const parseTimeString = (timeString) => {
  if (typeof timeString === "number") {
    return timeString
  } else if (typeof timeString === 'string') {
    const timeArray = timeString.split(':')
    if (timeArray.length===3) { //3:30:39 --> 3 hrs, 30min, 39s
      return timeArray[0]*60*60 + timeArray[1] * 60 + timeArray[2]
    }
    else if (timeArray.length===2) { // ex 3:15 --> 3min, 15s
      return timeArray[0]*60 + timeArray[1]
    } else if (timeArray.length===1) {
      return timeArray[0]
    } else {
      console.error("There was a problem parsing the time string")
    }
  } else {
    console.error('ParseTimeString failed completely')
  }
}

export const seekToVideoTime = (ref, time) => {
  ref.current.seekTo(parseFloat(time));
};

export const calculateTotalWorkoutTime = (workout) => {
  // console.log(workout)
  let sum = 0;
  workout.structure.forEach(section => {
    section.moveList.forEach((move)=>{
      move.videos.forEach((video)=>{
        let duration = video.endTime - video.startTime
        sum += duration
      })
    })
  });
  return sum;
};


/*
const calculateWorkoutPercentage = (timeList, currentMove) => {
  let timeTotal = calculateTotalWorkoutTime(timeList);
  let timeToCurrent = calculateTotalWorkoutTime(timeList.slice(0, currentMove));
  let percentage = timeToCurrent / timeTotal;
  return percentage;
};



const flattenWorkoutTimes = (workout) => {
  let timeArray = [];
  workout.forEach((section) =>
    section.moveList.forEach((move) => timeArray.push(move.time))
  );
  return timeArray;
};

const testWorkoutTimeList = flattenWorkoutTimes(testWorkout.workout)
const firstMovePercentage = calculateWorkoutPercentage(testWorkoutTimeList, 2)
console.log(firstMovePercentage)

currently working on getting a time for the total workout time (will need to add rest time to workout)
workout will need customizable rest times based on the move but there should also be a default option so I don't have to manually adjust rest times for each move, probably ideally saved as a "rest time option on the moves themselves" in case a certain move needs more time for setup, also what about rest time between sections?

function to set new video

const handleAdvanceVideo = (
  currentTime,
  endTime,
  loop,
  currentVideoIndex,
  videoArr
) => {
  if (currentTime >= endTime) {
    if (loop === true) {
      console.log(`execute restart function`);
    } else {
      if (currentVideoIndex === videoArr.length - 1) {
        //set video selector to 0
        //check if you need to increase the current video index
      } else {
        //increase the current video selector by 1
      }
    }
  }
};

if loop is true and you reach the end time then set the playback to the start time

const resetLoopVideo = (video, startTime, endTime) => {
  need to figure out how to control playback from these util functions
};


*/
