import React, { useState } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";
import { analytics } from "../../Firebase/firebase.utils";

import "./SignUp.styles.scss";

import FormInput from "../FormInput/FormInput.component";
import CustomButton from "../CustomButton/CustomButton.component";
import CircularProgress from '@material-ui/core/CircularProgress'


const registerUser = async (values, actions) => {
  // console.log("Async Submit Workouts Triggered");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(values, null, 2),
  };
  // console.log(requestOptions);
  try {
    const response = await fetch(
      "https://us-central1-waterstone-98dcd.cloudfunctions.net/api/signup",
      requestOptions
    );
    if (response.status === 201) {
      const data = await response.json();
      // console.log(data);
      return data;
    } else {
      throw Error(await response.text());
    }
  } catch (err) {
    alert("There was an error registing your account")
    console.error(err);
  }

  //actions.setSubmitting(false);
};

const SignUp = ({ setCurrentUser }) => {
  //this can be managed with formik (already including it)
  const [state, setState] = useState({
    displayName: "",
    handle: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false)
  const { displayName, handle, email, password, confirmPassword } = state;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const { displayName, email, password, confirmPassword } = state;
    //Validation
    // eslint-disable-next-line no-useless-escape
    if (!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
      setIsLoading(false)
      alert("Please Enter a Valid Email Address")
      return;
    }
    if (displayName.trim() === "") {
      setIsLoading(false)
      alert("Name Required");
      return;
    } else if (displayName.length > 100) {
      setIsLoading(false)
      alert("Your name is limited to 100 characters");
      return;
    }
    if (password !== confirmPassword) {
      setIsLoading(false)
      alert("passwords don't match");
      return;
    }
    try {
      //Register to DB (this is where you plug in your api and remove auth)
      // const {user} = await auth.createUserWithEmailAndPassword(email, password);
      // await createUserProfileDocument(user, {displayName});
      const userProfile = await registerUser(state);
     
      if (userProfile){
        analytics.logEvent('sign_up', {method: 'email'})
        setIsLoading(false)
        setState({
          displayName: "",
          handle: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        setCurrentUser(userProfile);
      }
    } catch (error) {
      alert("There was a problem registering your account")
      console.error(error);
    }
    setIsLoading(false)
  };
  return (
    <div className="sign-up-container">
      <h2 className="title">I Don't Have An Account</h2>
      <span>Create An Account with Email and Password</span>
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <FormInput
          value={displayName}
          type="text"
          onChange={handleChange}
          name="displayName"
          required
          label="Your Name"
        />
        <FormInput
          value={email}
          type="email"
          onChange={handleChange}
          name="email"
          required
          label="Email"
        />
        <FormInput
          value={handle}
          type="text"
          onChange={handleChange}
          name="handle"
          required
          label="Username"
        />
        <FormInput
          value={password}
          type="password"
          onChange={handleChange}
          name="password"
          required
          label="Password"
        />
        <FormInput
          value={confirmPassword}
          type="password"
          onChange={handleChange}
          name="confirmPassword"
          required
          label="Confirm Password"
        />
        <CustomButton type="submit">Sign Up</CustomButton>
      </form>
      {isLoading ? <CircularProgress /> : null}
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  setCurrentUser: (userObj) => dispatch(setCurrentUser(userObj)),
});

export default connect(null, mapDispatch)(SignUp);
