import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom"

// import VideoInput from "../../Components/VideoInput/VideoInput.component"
// import ModalMoveEditor from "../../Components/ModalMoveEditor/ModalMoveEditor.compontent"
import WorkoutForm from "../../Components/WorkoutForm/WorkoutForm.component"

import "./WorkoutConstructor.styles.scss";


const WorkoutConstructor = () => {
  let { id } = useParams();
  const [workout, setWorkout] = useState()
  useEffect(() => {
    const getWorkoutInfo = async () => {
      let res = await fetch(`https://us-central1-waterstone-98dcd.cloudfunctions.net/api/workout/${id}`)
      let workoutData = await res.json()
      if (!workoutData.error) {
        // console.log(workoutData)
        setWorkout(workoutData)
      }
      else {console.error(workoutData.error)}
    }
    getWorkoutInfo()
  }, [id])
  return (
  <div>
    {id ? (<h1>Edit Workout</h1>):(<h1>Create New Workout</h1>)}
    <WorkoutForm workout={workout} type={id ? "UPDATE": "CREATE"}/>
  </div>
)};

export default WorkoutConstructor;
