import React from "react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux'

import ProfileDropDown from './ProfileDropDown/ProfileDropDown.compoent'
import ContactButton from "../ContactButton/ContactButton.component"

import "./Header.styles.scss";

const Header = ({ currentUser }) => {
  return (
    <nav className="header">
      <Link to="/">
        <div className="logo-container">
          <img
            src="https://uploads-ssl.webflow.com/5dab3a4665b2d9c6fe074a8f/5ed19de5e36a2bda9cc35ab0_WaterStone-Logo-2.png"
            alt="logo to home"
            className="logo"
          />
        </div>
      </Link>
      <div className="options">
        {/* <Link className="option" to="/browse">
          Browse
        </Link> */}
        <Link className="option" to="/w/koc1x5qW2JTFd3mfmeWK">
          Test A Workout
        </Link>
        <Link className="option" to="/create">
          Create Workout
        </Link>
        {currentUser ? (
          <div className="option"><ProfileDropDown/></div>
        ) : (
          <Link className="option" to="/signin">
            Sign In
          </Link>
        )}
        <ContactButton/>
      </div>
    </nav>
  );
};

const mapState = state => ({
  currentUser: state.user.currentUser
})



export default connect(mapState)(Header);
