import USER_TYPES from "./user.types"

import {auth} from "../../Firebase/firebase.utils"

const INITIAL_STATE = {
  currentUser: null,
  userAuth: null,
  userToken: null,
  //Something goes wrong with persisting a Map in redux-persist, it converts it to an empty object due to JSON.stringify() not supporting maps favoriteWorkouts: new Map(),
  favoriteWorkouts: [],
  isFetching: false
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_TYPES.SET_CURRENT_USER:
      //handle logout
      if (action.payload === null){
        auth.signOut()
        return {
          currentUser: null,
          userAuth: null,
          userToken: null,
          favoriteWorkouts: [],
          error: "",
        }
      } else {
        return {
          ...state,
          currentUser: action.payload
        }
      }
      case USER_TYPES.SET_USER_AUTH:
        //createAuthInstance(action.payload)
        return {
          ...state,
          userAuth: action.payload
        }
    case USER_TYPES.SET_USER_TOKEN:
        return {
          ...state,
          userToken: action.payload
        }
    case USER_TYPES.SET_FAVORITE_WORKOUTS: 
      return {
        ...state,
        favoriteWorkouts: action.payload
      }
    case USER_TYPES.ADD_FAVORITE_WORKOUT:
      return {
        ...state,
        favoriteWorkouts: [...state.favoriteWorkouts, action.payload]
      }
    case USER_TYPES.DELETE_FAVORITE_WORKOUT:
        return{
          ...state,
          favoriteWorkouts: state.favoriteWorkouts.filter(favId => favId !== action.payload)
        }
    case USER_TYPES.FETCH_FAVORITES_START:
      return {
        ...state,
        isFetching: true
      }
    case USER_TYPES.FETCH_FAVORITES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          favoriteWorkouts: action.payload
        }
    case USER_TYPES.FETCH_FAVORITES_FAILURE:
          return {
            ...state,
            isFetching: false,
            error: action.payload
          }
    default:
      return state
  }
}

export default userReducer;