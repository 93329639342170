import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { auth } from "../../Firebase/firebase.utils";
import { setUserToken } from "../../redux/user/user.actions";

const ProtectedRoute = ({
  component: Component,
  currentUser,
  userAuth,
  userToken,
  setUserToken,
  ...rest
}) => {
  //Ideally anytime we're accessing a protected route we make sure that we have the auth token and it's valid, if it's not then we need to prompt the user to sign in and then on completetion redirect them to the original route
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        auth.currentUser.getIdToken().then((newToken) => {
          if (newToken !== userToken) {
            setUserToken(newToken);
          }
        });
      } else {
        setUserToken(null);
      }
    });
    return () => {
      unsubscribeFromAuth();
    };
  }, [userAuth, setUserToken, userToken]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userToken) {
          return (
            <Component
              {...rest}
              {...props}
              currentUser={currentUser}
              userToken={userToken}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

//So the big question right now is where do I put the code to get the user object so it loads before
const mapState = (state) => ({
  currentUser: state.user.currentUser,
  userToken: state.user.userToken,
});

const mapDispatch = (dispatch) => ({
  setUserToken: (token) => dispatch(setUserToken(token)),
});

export default connect(mapState, mapDispatch)(ProtectedRoute);

// useEffect(() => {
//   //this code doesnt run until the whole app is done loading, thats the cause of the delay
//   const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
//     console.log("auth state change logged");
//     if (userAuth) {
//       setInstanceAuthToken();
//       console.log(userAuth);

//       console.log(`Instance defaults ${instance.defaults}`);
//       //THis works, the problem is that on the favorites page, I get a race condition, where the refresh token is eventually called and then after it's gotten it actually goes out and fetches the data. I need to make sure that when the page loads it has the most up to date refresh token. This seems like a good job for redux thunk or saga. I do have a guess that what I could do is put it as a method on the persist store token and just display null until it's resolved
//       instance.get("/favorites").then((response) => console.log(response));
//       //get or create profile in the database - This should return the object that I'm looking for
//       /* This is one of the biggest things that slows down my development right now, I don't know what the user object that I'm constructing needs to look like and I have to figure out where it is each time and then figure out how to create it over and over again
//       What I need is a typscript interface that is my single source of truth for what the user profile document needs to look like

//       I made that, what i need is this user profile document with it's methods to get the token, but I also need the additional data for the user like their favorites, username, profile picture, ect.

//       */
//       //This will find their profile in the database based on the UID and then either build it or return the existing one depending on if it exists or not
//       // const userProfile = await createUserProfileDocument(userAuth)
//       //set that in the redux store
//       // setCurrentUser(userProfile)
//       //then you'll want to create a auth.currentUser.getIdToken call to set the auth header for your default axios instance
//     }
//   });
//   return () => {
//     unsubscribeFromAuth();
//     //setCurrentUser(null)
//   };
// }, []);
