import { object, string, array, number, ref } from "yup";
const Yup = { object, string, array, number, ref };

export const videoValidationSchema = Yup.object({
  videoTitle: Yup.string().trim().min(4, "Title Must Be 4 Characters"),
  url: Yup.string()
    .url("Must Be A Valid URL")
    .trim()
    .required("You must provide a URL"),
  startTime: Yup.number("Must Be A Number")
    .integer("Must Be An Integer")
    .min(0, "Must Be A Positive Number")
    .required("A Start Time Is Required"),
  endTime: Yup.number("Must Be A Number")
    .integer("Must be an integer")
    .required("An End Time is Required")
    .min(Yup.ref("startTime"), "Must be larger than start time"),
});

export const moveValidationSchema = Yup.object({
  moveName: Yup.string().required().max(100, "Must Be 100 Characters or Less"),
  videos: Yup.array().of(videoValidationSchema),
});

export const workoutValidationSchema = Yup.object({
  workout: Yup.object({
    name: Yup.string()
      .min(4, "Must be longer Than 4 characters")
      .max(100, "Must be 100 characters or less")
      .required("Required")
      .trim(),
    description: Yup.string().max(5000, "Must be 5000 characters or less"),
    thumbnailUrl: Yup.string().url("Must Be A Valid URL").trim(),
    textDuration: Yup.string()
      .min(4, "Must be longer Than 4 characters")
      .trim(),
    structure: Yup.array()
      .of(
        Yup.object({
          sectionTitle: Yup.string()
            .min(5)
            .max(100, "Must be 100 characters or less")
            .trim()
            .required("Must Not Be Empty"),
          moveList: Yup.array()
            .required("You Must Have At Least One Move")
            .max(100, "You Are Limited to 100 Moves Per Section")
            .of(moveValidationSchema),
        })
      )
      .required("You Must Have At Least One Sections")
      .max(100, "You Are Limited to 100 Sections"),
  }),
});
