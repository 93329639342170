import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import MoveList from "../../Components/MoveList/MoveList";
import CurrentMoveTimer from "../../Components/CurrentMoveTimer/CurrentMoveTimer";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer.component";
import MoveInfoDrawer from "../../Components/MoveInfoDrawer/MoveInfoDrawer.component";
import FavoriteButton from "../../Components/FavoriteButton/FavoriteButton.component"
import { setCurrentWorkout } from "../../redux/workout/workout.actions";

// import CircularProgress from '@material-ui/core/CircularProgress';
import "./WorkoutPage.scss";
// import Drawer from "@material-ui/core/Drawer";
//import {SwipeableTemporaryDrawer as myWrappedDrawerTest} from "../../Components/SwipeableDrawer/SwipeableDrawer.component";
// import DemoPlayer from "../../Components/VideoPlayer/DemoPlayer.component";

const WorkoutPage = ({ workoutName, playing, setCurrentWorkout }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(true);
  let { id: workoutId } = useParams();
  useEffect(() => {
    const fetchWorkoutData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`https://us-central1-waterstone-98dcd.cloudfunctions.net/api/workout/${workoutId}`)
        if (res.status !== 200){
          throw res
        } else {
          const workoutData = await res.json()
          setCurrentWorkout({...workoutData, workoutId})
          setIsValid(true)
        }   
      } catch (err) {
        console.error(err)
        setIsValid(false)
        if (err.status === 404) {
          setError("Workout Not Found")
        } else if (err.status === 500) {
          setError("There was an error with fetching the workout data...");
        }
      }
      setIsLoading(false);
    };
    fetchWorkoutData();
  }, [workoutId, setCurrentWorkout]);
  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  return (
    <div>
      {!isLoading && !isValid ? (
       <h1>{error}</h1>
      ) : (
        <div className="main">
          {isLoading ? (
            <h1>Loading...</h1>
          ) : (
            <div>
              <h1>{workoutName}</h1>
              <FavoriteButton workoutId={workoutId}/>
              <Link to={`/w/${workoutId}/edit`}>Edit Workout</Link>
              <div className="move-info-container">
                <div className="media-container">
                  {/* <DemoPlayer/> */}
                  <VideoPlayer playing={playing} />
                </div>
                <div className="player-overlay-text">
                  {/* <Drawer open={false} anchor="left">
            
          </Drawer> */}

                  {/* <myWrappedDrawerTest /> */}
                </div>
              </div>
              <div>
                <CurrentMoveTimer />
              </div>
              <div className="scroll-container">
                <MoveList />
              </div>
              <MoveInfoDrawer />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapState = (state) => ({
  workoutName: state.workout.currentWorkout.name,
  playing: state.workout.isStart,
  videoRef: state.workout.videoRef,
});

const mapDispatch = (dispatch) => ({
  setCurrentWorkout: (workoutData) => dispatch(setCurrentWorkout(workoutData)),
});

export default connect(mapState, mapDispatch)(WorkoutPage);
