import React from "react";
import {withRouter} from "react-router-dom"

import "./CategoryCard.styles.scss";

const CategoryCard = ({title, duration, difficulty, imageUrl, history, match, linkUrl}) => {
  return (
    <div className="category-card hvr-back-pulse" onClick={()=>history.push(`${match.url}${linkUrl}`)}>
    <img src={imageUrl} alt={`workout ${title}`} className="background-image"/>
      <div className="content">
        <h1 className="title">{title}</h1>
        <span className="subtitle">{duration}, {difficulty}</span>
      </div>
    </div>
  );
};

export default withRouter(CategoryCard);
