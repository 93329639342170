import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CustomInput from "../CustomInput/CustomInput.component";
import { v4 as uuidv4 } from "uuid";
import { calculateTotalWorkoutTime } from "../../redux/workout/workout.utils";
import { fmtSec } from "../../Util/countdown.utility";

import "./WorkoutForm.styles.scss";

import { Form, Formik, FieldArray } from "formik";
import { workoutValidationSchema } from "../../Util/validation.utility";

const blankWorkout = {
  name: "",
  description: "",
  thumbnailUrl: "",
  textDuration: "",
  structure: [
    {
      sectionTitle: "",
      moveList: [
        {
          moveName: "",
          videos: [
            {
              videoTitle: "",
              url: "",
              startTime: 0,
              endTime: 0,
            },
          ],
        },
      ],
    },
  ],
};

const WorkoutForm = ({ workout = blankWorkout, currentUserToken, action }) => {
  const history = useHistory();
  // const [isLoading, setIsLoading] = useState(false);
  const redirectToNewWorkout = (id) => {
    history.push(`/w/${id}`);
  };
  //For eventually adding UIDs to every move to fix the bug
  const addWorkoutIds = (workoutWithoutData) => {
    const workoutWithData = workoutWithoutData;
    // do a forEach loop on all of the moves added, generate a uid for each, and then add that to the object.
    workoutWithData.id = uuidv4();
    workoutWithData.structure.forEach((section) => {
      section.id = uuidv4();
      section.moveList.forEach((move) => {
        move.id = uuidv4();
        move.videos.forEach((video) => (video.id = uuidv4()));
      });
    });
    // return the new object with UIDs Added
    return workoutWithData;
  };

  const submitWorkout = async (values, actions) => {
    // console.log("Async Submit Workouts Triggered");

    const workoutToSubmit = addWorkoutIds(values.workout);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUserToken}`,
      },
      body: JSON.stringify(workoutToSubmit, null, 2),
    };
    // console.log(requestOptions);
    const response = await fetch(
      "https://us-central1-waterstone-98dcd.cloudfunctions.net/api/workout",
      requestOptions
    );
    actions.setSubmitting(false);
    // console.log("data has been fetched");
    // console.log(response);
    const data = await response.json();
    if (response.status === 201) {
      alert(data.message);
      redirectToNewWorkout(data.id);
    } else {
      alert(data.message);
    }
    // console.log(data);
  };

  return (
    <Formik
      initialValues={{ workout }}
      enableReinitialize={true}
      onSubmit={submitWorkout}
      validationSchema={workoutValidationSchema}
    >
      {(props) => (
        <Form className="workout-constructor-form">
          <div className="workout-form-card">
            <button
              className="clear-btn"
              onClick={() => {
                let confirmed;
                confirmed = window.confirm(
                  "Are you sure you want to clear the entire form? You will lose all work."
                );
                if (confirmed) {
                  props.resetForm({
                    values: { workout: blankWorkout },
                  });
                }
              }}
            >
              Clear Form
            </button>
            <div className="workout-metadata">
              <CustomInput
                name="workout.name"
                label="Workout Name"
                type="text"
                required
              />
              <CustomInput
                name="workout.description"
                label="Description"
                type="text"
              />

              <CustomInput
                name="workout.thumbnailUrl"
                label="Thumbnail URL"
                type="text"
              />
              <CustomInput
                name="workout.textDuration"
                label="Duration to Display"
                type="text"
              />
              <p>
                Total Workout Time:{" "}
                {props.isValid
                  ? fmtSec(calculateTotalWorkoutTime(props.values.workout))
                  : "..."}
              </p>
            </div>
            <FieldArray name="workout.structure">
              {(sectionHelpers) => {
                const workout = sectionHelpers.form.values.workout;
                return (
                  <div className="workout-structure">
                    {workout.structure.map(
                      (section, sectionIdx, sectionArr) => {
                        return (
                          <div
                            key={`section-${sectionIdx}`}
                            className={`section-container`}
                          >
                            <CustomInput
                              name={`workout.structure.${sectionIdx}.sectionTitle`}
                              type="text"
                              label="Section Title"
                            />
                            <FieldArray
                              name={`workout.structure.${sectionIdx}.moveList`}
                            >
                              {(moveHelpers) => {
                                return (
                                  <div className={`section-moves-container`}>
                                    {section.moveList.map(
                                      (move, moveIdx, moveArr) => {
                                        return (
                                          <div
                                            key={`section-${sectionIdx}_move-${moveIdx}`}
                                            className={`single-move-container`}
                                          >
                                            <CustomInput
                                              name={`workout.structure[${sectionIdx}].moveList[${moveIdx}].moveName`}
                                              type="text"
                                              label="Move Name"
                                            />
                                            <FieldArray
                                              name={`workout.structure[${sectionIdx}].moveList[${moveIdx}].videos`}
                                            >
                                              {(videoHelpers) => {
                                                return (
                                                  <div className="videoList-container">
                                                    {move.videos.map(
                                                      (
                                                        video,
                                                        videoIdx,
                                                        videoArr
                                                      ) => (
                                                        <div
                                                          key={`section-${sectionIdx}__move-${moveIdx}__video-${videoIdx}`}
                                                          className="videoList"
                                                        >
                                                          <CustomInput
                                                            name={`workout.structure[${sectionIdx}].moveList[${moveIdx}].videos[${videoIdx}].url`}
                                                            type="url"
                                                            label="Video URL"
                                                          />
                                                          <CustomInput
                                                            name={`workout.structure[${sectionIdx}].moveList[${moveIdx}].videos[${videoIdx}].startTime`}
                                                            type="number"
                                                            label="Start Time"
                                                          />
                                                          <CustomInput
                                                            name={`workout.structure[${sectionIdx}].moveList[${moveIdx}].videos[${videoIdx}].endTime`}
                                                            type="number"
                                                            label="End Time"
                                                          />
                                                          <button
                                                            disabled={
                                                              videoIdx === 0
                                                            }
                                                            onClick={() =>
                                                              videoHelpers.move(
                                                                videoIdx,
                                                                videoIdx - 1
                                                              )
                                                            }
                                                            className="video option-btn"
                                                          >
                                                            Move Down
                                                          </button>
                                                          <button
                                                            disabled={
                                                              videoIdx ===
                                                              videoArr.length -
                                                                1
                                                            }
                                                            onClick={() =>
                                                              videoHelpers.move(
                                                                videoIdx,
                                                                videoIdx + 1
                                                              )
                                                            }
                                                            className="video option-btn"
                                                          >
                                                            Move Up
                                                          </button>
                                                          <button
                                                            onClick={() =>
                                                              videoHelpers.remove(
                                                                videoIdx
                                                              )
                                                            }
                                                            className="video option-btn delete-btn"
                                                          >
                                                            Remove Video
                                                          </button>
                                                        </div>
                                                      )
                                                    )}
                                                    <button
                                                      className="option-btn"
                                                      onClick={() =>
                                                        videoHelpers.push({
                                                          url: "",
                                                          startTime: 0,
                                                          endTime: 0,
                                                        })
                                                      }
                                                    >
                                                      Add Video
                                                    </button>
                                                  </div>
                                                );
                                              }}
                                            </FieldArray>
                                            <button
                                              disabled={moveIdx === 0}
                                              onClick={() =>
                                                moveHelpers.move(
                                                  moveIdx,
                                                  moveIdx - 1
                                                )
                                              }
                                              className="move option-btn"
                                            >
                                              Move Down
                                            </button>
                                            <button
                                              disabled={
                                                moveIdx === moveArr.length - 1
                                              }
                                              onClick={() =>
                                                moveHelpers.move(
                                                  moveIdx,
                                                  moveIdx + 1
                                                )
                                              }
                                              className="move option-btn"
                                            >
                                              Move Up
                                            </button>
                                            <button
                                              onClick={() =>
                                                moveHelpers.remove(moveIdx)
                                              }
                                              className="move option-btn delete-btn"
                                            >
                                              Remove move
                                            </button>
                                          </div>
                                        );
                                      }
                                    )}
                                    <button
                                      className="option-btn"
                                      onClick={() =>
                                        moveHelpers.push(
                                          blankWorkout.structure[0].moveList[0]
                                        )
                                      }
                                    >
                                      Add Move
                                    </button>
                                  </div>
                                );
                              }}
                            </FieldArray>
                            <button
                              disabled={sectionIdx === 0}
                              onClick={() =>
                                sectionHelpers.move(sectionIdx, sectionIdx - 1)
                              }
                              className="section option-btn"
                            >
                              Move Section Earlier
                            </button>
                            <button
                              disabled={sectionIdx === sectionArr.length - 1}
                              onClick={() =>
                                sectionHelpers.move(sectionIdx, sectionIdx + 1)
                              }
                              className="section option-btn"
                            >
                              Move Section Later
                            </button>
                            <button
                              onClick={() => sectionHelpers.remove(sectionIdx)}
                              className="section option-btn delete-btn"
                            >
                              Remove Section
                            </button>
                          </div>
                        );
                      }
                    )}
                    <button
                      className="option-btn"
                      onClick={() =>
                        sectionHelpers.push(blankWorkout.structure[0])
                      }
                    >
                      Add Section
                    </button>
                  </div>
                );
              }}
            </FieldArray>
            {/* 
          So what I want this to look like is the following
          <CustomArrayInput name="structure" type="nestedArray" label="Structure">
            <CustomInput name="workout.structure.sectionTitle"/>
            <CustomArrayInput name="moveList" type="nestedArray">
              <CustomInput name=""/>
              //this video input should automatically handle itself with populating fields and 
              <CustomArrayInput name="videos" type=nestedArray/> 
            </CustomArrayInput>
          </CustomArrayInput>

          Custom array inputs should only dispaly their direct discendents and show nesting as an option that when clicked on will open a new modal window like todoist

          CustomInputs should display paragraph text and switch to an input when edit is clicked, when save button is clicked or another edit window is opened it should save the currently open window, switch it back to a div, and then open the new window (maybe set all to false and then this one to true)

        
        
         */}
            <button
              disabled={!props.dirty}
              type="submit"
              className="submit-btn"
            >
              Submit
            </button>
            {!props.isValid ? <p>The form is not valid</p> : null}
            {props.isValidating ? (
              <div>
                <p>Validating...</p>
              </div>
            ) : null}
            {props.isSubmitting ? (
              <div>
                <p>Submitting...</p>
              </div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapState = (state) => ({
  currentUserToken: state.user.currentUser.token,
});

export default connect(mapState)(WorkoutForm);

/* 

Test to access nested values
<CustomInput name="workout.structure[0].moveList[0].videos[0].url" />
        <div>{console.log(workout.structure[0].moveList[0].videos[0].url)}</div> 
        


//Yup Validation for the structure array
        Yup.object({
          sectionTitle: Yup.string()
            .min(5)
            .max(100, "Must be 100 characters or less")
            .trim()
            .required("Must Not Be Empty"),
          moveList: Yup.array()
            .required("You Must Have At Least One Move")
            .max(100, "You Are Limited to 100 Moves Per Section")
            .of(
              Yup.object({
                moveName: Yup.string()
                  .required()
                  .max(100, "Must Be 100 Characters or Less"),
                videos: Yup.array().of(
                  Yup.object({
                    videoName: Yup.string()
                      .required()
                      .max(100, "Must Be 100 Characters or Less"),
                    url: Yup.string().url("Must Be A Valid URL").trim().required(),
                    startTime: Yup.number().integer().min(0, "Must Be A Positive Number").required(),
                    endTime: Yup.number()
                      .integer()
                      .required()
                      .min(
                        Yup.ref("startTime"),
                        "Must be larger than start time"
                      ),
                  })
                ),
              })
*/
