import { createSelector } from "reselect";

export const getCurrentUser = (state) => state.user.currentUser;

export const getCurrentUserName = createSelector([getCurrentUser], (user) => {
  return user.handle;
});

export const getCurrentToken = createSelector([getCurrentUser], (user) => {
  return user.token;
});

export const isWorkoutFav = (state, props) => {
  // console.log(state.user.favoriteWorkouts);
  // console.log(props.workoutId);
  return state.user.favoriteWorkouts.includes(props.workoutId);
};
