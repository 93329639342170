import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";
import { getCurrentVideo } from "../../redux/workout/workout.selectors";
import {
  setNextVideo,
  setCurrentTime,
  playWorkout,
  pauseWorkout,
  setVideoRef,
  updateGlobalTimer,
} from "../../redux/workout/workout.actions";

import "./VideoPlayer.styles.scss";

import useSound from 'use-sound';
import preBeep from "../../Sounds/Pre_Beep.mp3"
import switchBeep from "../../Sounds/Switch_Beep.wav"

const VideoPlayer = ({
  setNextVideo,
  setCurrentTime,
  playing,
  playWorkout,
  pauseWorkout,
  currentVideo,
  setVideoRef,
  updateGlobalTimer,
  globalTimer,
}) => {
  const { url, startTime, endTime } = currentVideo;
  const { workoutTimer, moveTimer, videoTimer } = globalTimer;
  const videoRef = useRef(null);
  const seekToTime = (time) => {
    videoRef.current.seekTo(parseFloat(time));
    setPreviousUpdateTime(time);
  };
  const [previousUpdateTime, setPreviousUpdateTime] = useState(0);
  const [playPreBeep] = useSound(preBeep, {interrupt: true});
  const [playSwitchBeep] = useSound(switchBeep, {interrupt: true});

  return (
    <div className="video-container">
      <ReactPlayer
        className="video-player"
        ref={videoRef}
        playing={playing}
        autoPlay={true}
        url={url}
        controls={true}
        onStart={() => {
          const timeAtStart = videoRef.current.getCurrentTime();
          if (timeAtStart <= startTime) {
            playWorkout()
            seekToTime(startTime);
          }
          
        }}
        onProgress={(e) => {
          if (e.playedSeconds > endTime-3 && e.playedSeconds < endTime) {
            playPreBeep()
          }
          if (e.playedSeconds >= endTime) {
            playSwitchBeep()
            setNextVideo();
            return;
          }
          if (e.playedSeconds > startTime) {
            setCurrentTime(e.playedSeconds); //updates the time in the app as long as the time in the video is after the start time (to prevent larger than startTime numbers)
          }
          if (e.playedSeconds > previousUpdateTime + 1) {
            updateGlobalTimer({
              workoutTimer: workoutTimer + 1,
              moveTimer: moveTimer + 1,
              videoTimer: videoTimer + 1,
            });
            setPreviousUpdateTime(e.playedSeconds);
          }
        }}
        onReady={() => {
          setVideoRef(videoRef);
        }}
        onPlay={() => playWorkout()}
        onPause={() => {pauseWorkout()}}
        onEnded={() => setNextVideo()}
      />
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  setNextVideo: () => dispatch(setNextVideo()),
  setCurrentTime: (time) => dispatch(setCurrentTime(time)),
  playWorkout: () => dispatch(playWorkout()),
  pauseWorkout: () => dispatch(pauseWorkout()),
  setVideoRef: (ref) => dispatch(setVideoRef(ref)),
  updateGlobalTimer: (timerState) => dispatch(updateGlobalTimer(timerState)),
});

const mapState = (state) => ({
  currentVideo: getCurrentVideo(state),
  globalTimer: state.workout.globalTimer,
});

export default connect(mapState, mapDispatch)(VideoPlayer);

//so what I need to do is to get the video url passed to the demo player

/*1. execute a handle video advance function that will need to check if move time is 0 and if it's not then to seek to start time to loop the video, move time will need to be default of start to end time unless explicitly set (will need to put that in a function above), will also need some way to globally store the remaining move duration and subtract 1 from it for every second that the video plays
  //after this get the moves to display the information with them to remind me of things.
  //also film yourself just doing these moves so you can actually follow along with your own workout 
  
  (e) => {
    console.log('onProgress')
    if (moveCountDown === 0) {
      console.log(moveCountDown)
      console.log('movecountdown is 0 advancing to next video')
      setNextVideo(); //advances to the next video after reaching endTime
      return
    } else if (e.playedSeconds > endTime) {
      console.log('looping video')
      seekToTime(startTime);
      return
    } else {
      console.log(`subtracting 1 from ${moveCountDown}`)
      setMoveCountDown(moveCountDown-1);
      console.log(moveCountDown)
    } 
  }
  
  
  
  */
