import USER_TYPES from "./user.types"
import {auth} from "../../Firebase/firebase.utils"

import {firestore} from "../../Firebase/firebase.utils"

export const setCurrentUser = user => ({
  type: USER_TYPES.SET_CURRENT_USER,
  payload: user
})

export const setUserAuth = userAuth => ({
  type: USER_TYPES.SET_USER_AUTH,
  payload: userAuth
})

export const setUserToken = token => ({
  type: USER_TYPES.SET_USER_TOKEN,
  payload: token
})

export const setFavoriteWorkouts = workouts => ({
  type: USER_TYPES.SET_FAVORITE_WORKOUTS,
  payload: workouts
})

export const deleteFavoriteWorkout = (id) => ({
  type: USER_TYPES.DELETE_FAVORITE_WORKOUT,
  payload: id
})

export const addFavoriteWorkout = (id) => ({
  type: USER_TYPES.ADD_FAVORITE_WORKOUT,
  payload: id
})


export const fetchFavoriteWorkoutsStart = () => ({
  type: USER_TYPES.FETCH_FAVORITES_START
})

export const fetchFavoriteWorkoutsSuccess = (favoriteWorkouts) => ({
  type: USER_TYPES.FETCH_FAVORITES_SUCCESS,
  payload: favoriteWorkouts
})

export const fetchFavoriteWorkoutsFailure = (error) => ({
  type: USER_TYPES.FETCH_FAVORITES_FAILURE,
  payload: error
})

export const fetchFavoriteWorkoutsStartAsync = () => {
  //calling this returns a function that can recieve dispatch (so thunk can give the function the ability to dispatch to the store)
  // console.log(`FAVORITE WORKOUTS WAS CALLED WITH ID OF ${id}`)
  return dispatch => {
    // console.log(dispatch)
    const favoriteWorkoutsRef = firestore.collection(`/users/${auth.currentUser.uid}/favorites`)
    dispatch(fetchFavoriteWorkoutsStart())
    favoriteWorkoutsRef.get().then(snapshot => {
      // console.log(snapshot)
      let favoritesArr = []
      snapshot.docs.forEach(doc=>favoritesArr.push(doc.id))
      dispatch(fetchFavoriteWorkoutsSuccess(favoritesArr))
    }).catch(err => {
      // console.error(err)
      dispatch(fetchFavoriteWorkoutsFailure(err))
    })
  }
}