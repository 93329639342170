import React, { useState } from "react";
import { connect } from "react-redux";
import { auth, analytics } from "../../Firebase/firebase.utils";
import FormInput from "../FormInput/FormInput.component";
import CustomButton from "../CustomButton/CustomButton.component";
import "./SignIn.styles.scss";
import CircularProgress from '@material-ui/core/CircularProgress'
import { setCurrentUser, setUserAuth } from "../../redux/user/user.actions";

// import { signInWithGoogle } from "../../Firebase/firebase.utils";

const SignIn = ({ setCurrentUser, setUserAuth }) => {
  const [state, setState] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false)
  const { email, password } = state;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state, null, 2),
    };
    // console.log(requestOptions);
    try {
      let res = await fetch(
        `https://us-central1-waterstone-98dcd.cloudfunctions.net/api/login`,
        requestOptions
      );
      let data = await res.json();
      if (res.status === 200) {
        const userAuth = await auth.signInWithEmailAndPassword(email, password);
        analytics.logEvent('login', {method: 'email'})
        setState({ email: "", password: "" });
        setIsLoading(false);
        setUserAuth(userAuth);
        setCurrentUser(data);
      } else {
        throw data;
      }
    } catch (error) {
      console.error(error);
      console.error(
        `Error signing in with that combination of email and password: ${error}`
      );
    }
    setIsLoading(false)
  };
  return (
    <div className="sign-in-wrapper">
      <h2>I Already Have An Account</h2>
      <span>Sign In With Your Email And Password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          value={email}
          type="email"
          onChange={handleChange}
          name="email"
          required
          label="Email"
        />
        <FormInput
          value={password}
          type="password"
          onChange={handleChange}
          name="password"
          required
          label="Password"
        />
        <div className="buttons">
          <CustomButton type="submit">Sign In</CustomButton>
          {isLoading ? <CircularProgress /> : null}
          {/* <CustomButton onClick={signInWithGoogle} isGoogleSignIn>
            Sign With Google
          </CustomButton> */}
        </div>
      </form>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setUserAuth: (userAuth) => dispatch(setUserAuth(userAuth)),
});

export default connect(null, mapDispatch)(SignIn);
