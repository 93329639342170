import React from 'react'

import WorkoutCategories from "../../Components/WorkoutCategories/WorkoutCategories.component"

import "./MainPage.styles.scss"
import Typography from "@material-ui/core/Typography"

const MainPage = () => {
  return (
    <div className="featured-categories">
      <Typography variant="h1">Waterstone</Typography>
      <Typography variant="h2" style={{marginBottom: "10px"}}>A Practice Tool To Customize Workout Videos</Typography>
      <Typography variant="h6">Try testing one of the workouts below to start</Typography>
      <hr style={{marginBottom: "10px"}}></hr>
      <WorkoutCategories/>
    </div>
  )
}

export default MainPage


// Current Task: 1. Control the video with the move timer 2. Keep the video and the workout in sync