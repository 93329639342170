import WorkoutActionTypes from "./workout.types";

import { testWorkout } from "./workout.data";
import { getNextVideoID, seekToVideoTime } from "./workout.utils";
import { getVideoInfo } from "./workout.selectors";

const INITIAL_STATE = {
  currentWorkout: testWorkout,
  isStart: false,
  currentMove: { section: 0, move: 0, video: 0 },
  selectedMove: { section: 0, move: 0, video: 0 },
  currentTime: testWorkout.structure[0].moveList[0].videos[0].startTime,
  videoRef: null,
  currentMoveRef: null,
  moveInfoDisplay: false,
  globalTimer: { workout: {now: 0, duration: 0}, move: {now: 0, duration: 0}, video: {now: 0, duration: 0} },
};

const workoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WorkoutActionTypes.SET_CURRENT_WORKOUT:
      return {
        ...state,
        currentWorkout: action.payload,
      };
    case WorkoutActionTypes.TOGGLE_IS_START:
      return {
        ...state,
        isStart: !state.isStart,
      };
    case WorkoutActionTypes.PLAY_WORKOUT:
      return {
        ...state,
        isStart: true,
      };
    case WorkoutActionTypes.PAUSE_WORKOUT:
      return {
        ...state,
        isStart: false,
      };
    case WorkoutActionTypes.SET_CURRENT_MOVE_ID:
      seekToVideoTime(state.videoRef, getVideoInfo(state.currentWorkout.structure, action.payload).startTime);
      return {
        ...state,
        currentMove: action.payload,
        isStart: true,
        globalTimer: {moveTimer: 0, videoTimer: 0},
      };
      
    case WorkoutActionTypes.SET_NEXT_VIDEO_ID:
      // console.log(state.currentWorkout.structure)
      const currentWorkout = state.currentWorkout.structure
      const nextVideoId = getNextVideoID(currentWorkout, state.currentMove);
      const nextVideoInfo = getVideoInfo(currentWorkout, nextVideoId)
      const currentVideoInfo = getVideoInfo(currentWorkout, state.currentMove)
      if (nextVideoInfo.url === currentVideoInfo.url){
        seekToVideoTime(state.videoRef, nextVideoInfo.startTime)
      }
      // console.log(state.currentMoveRef)
      return {
        ...state,
        currentMove: nextVideoId,
        currentTime: nextVideoInfo.startTime,
      };
    case WorkoutActionTypes.SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };
    case WorkoutActionTypes.SET_VIDEO_REF:
      return {
        ...state,
        videoRef: action.payload,
      };
    case WorkoutActionTypes.DISPLAY_MOVE_INFO_PANEL:
      return {
        ...state,
        moveInfoDisplay: action.payload,
      };
    case WorkoutActionTypes.UPDATE_GLOBAL_TIMER:
      return {
        ...state,
        globalTimer: action.payload
      }
    case WorkoutActionTypes.SET_SELECTED_MOVE_ID:
      return {
        ...state,
        selectedMove: action.payload,
      };
    default:
      return state;
      
  }
};

export default workoutReducer;
