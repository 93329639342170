import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { isWorkoutFav } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { handleFavoriteAsync } from "../../Firebase/firebase.utils";
import {fetchFavoriteWorkoutsStartAsync, deleteFavoriteWorkout, addFavoriteWorkout} from '../../redux/user/user.actions'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

const FavoriteButton = ({
  workoutId,
  isFavorite,
  deleteFavoriteWorkout,
  addFavoriteWorkout
}: {
  workoutId: string;
  isFavorite: boolean;
  deleteFavoriteWorkout: (workoutId: string) =>void;
  addFavoriteWorkout: (workoutId:string) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  // const checkFavorite = useCallback((id: string) => userFavorites.has(id), [userFavorites]);
  const [favorited, setFavorited] = useState(isFavorite);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  // console.log(favorited)
  // console.log(`Is the workout ${workoutId} is in favorites? ${checkFavorite(workoutId)}`)
  useEffect(()=>{
    fetchFavoriteWorkoutsStartAsync()
  },[])


  const handleFavorited = async (newFavState: boolean) => {
    setLoading(true);
    let action: string;
    if (newFavState===true) {
      action = "ADD";
    } else {
      action = "DELETE";
    }
    try {
      // await setFavoriteWorkout(newFavState, workoutId);
      await handleFavoriteAsync(action, workoutId);
      setFavorited(newFavState);
      if (action === "ADD"){
        addFavoriteWorkout(workoutId)
      } else if (action === "DELETE") {
        deleteFavoriteWorkout(workoutId)
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <IconButton
        color="secondary"
        aria-label="favorite this workout"
        onClick={() => handleFavorited(!favorited)}
      >
        {favorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
    </>
  );
};

const mapState = (state: any, props:any) => ({
  isFavorite: isWorkoutFav(state, props)
});

const mapDispatch = (dispatch:any) =>({
  setUserFavorites: (favoritesMap:Map<string, any>) => dispatch(favoritesMap),
  deleteFavoriteWorkout: (workoutId:string) => dispatch(deleteFavoriteWorkout(workoutId)),
  addFavoriteWorkout: (workoutId:string) => dispatch(addFavoriteWorkout(workoutId))
})

export default connect(mapState, mapDispatch)(FavoriteButton);
