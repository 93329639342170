import React from "react";
import "./MoveList.scss";
import MoveListSection from "./MoveListSection/MoveListSection.component";
import { connect } from "react-redux";
import {
  getWorkoutStructure,
  getCurrentMoveInfo,
} from "../../redux/workout/workout.selectors";

//what I want is for each section of the workout to render it's section title and then each section to render the moves
//look at React Developer 2020 6.2 for pattern of how to achieve this (essentially, render a movelist for each section in a component above this and then have each section render it's sub moves and then in the higher component above this (could be the page)display it all in a scrolling container,  this would also be a great option for a styled component)

const MoveList = ({ workout, currentMoveId }) => {
  return (
    <div className="move-list-container">
      {workout.map(({ sectionTitle, moveList }, sectionIdx) => (
        <MoveListSection
          sectionTitle={sectionTitle}
          moveList={moveList}
          sectionIdx={sectionIdx}
          currentMoveId={currentMoveId}
        />
      ))}
    </div>
  );
};

const mapState = (state) => ({
  workout: getWorkoutStructure(state),
  currentMoveId: getCurrentMoveInfo(state).id,
});

export default connect(mapState)(MoveList);
