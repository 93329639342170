import React from "react";
import "./MoveInfoDrawer.styles.scss";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { connect } from "react-redux";

import { displayMoveInfoPanel } from "../../redux/workout/workout.actions";
import { getSelectedMoveInfo } from "../../redux/workout/workout.selectors";

import VideoDetailsList from "../VideoDetailsList/VideoDetailsList.component"


const MoveInfoDrawer = ({ open, moveInfo, displayMoveInfoPanel }) => {
  let moveName = moveInfo.moveName;
  let videos = moveInfo.videos;
  // console.log(moveInfo);
  

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    // console.log(`Toggle Drawer called with ${open}`);
    displayMoveInfoPanel(open);
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
      >
        <h1>{moveName}</h1>
        <h2>Videos</h2>
        <hr></hr>
        
        <VideoDetailsList videos={videos}/>
        
      </SwipeableDrawer>
    </div>
  );
};

const mapState = (state) => ({
  open: state.workout.moveInfoDisplay,
  moveInfo: getSelectedMoveInfo(state),
});

const mapDispatch = (dispatch) => ({
  displayMoveInfoPanel: (state) => dispatch(displayMoveInfoPanel(state)),
});

export default connect(mapState, mapDispatch)(MoveInfoDrawer);

/* 

Example of HTML STRUCTURE TO GENERATE
<h1>Right Splits</h1>
        <p>Stretch</p>
        <h2>Instructions</h2>
        <p>https://www.youtube.com/watch?v=GTBB3IekEwY</p>
        <h2>Substitutes</h2>
        <h3>If you need a different setup</h3>
        <ul>
          <li>Box Oversplits - <button>SWAP</button></li>
          <li>Standing Splits</li>
          <li>Supine Splits</li>
        </ul>
        <h3>Easier Moves</h3>
        <ul>
          <li>Dragon</li>
          <li>Runners Stretch</li>
          <li>Half Monkey</li>
        </ul>
        <h3>Harder Options</h3>
        <ul>
          <li>Box Oversplits</li>
          <li>Standing Leg Extension</li>
          <li>Bird Of Paradise</li>
        </ul>


*/
