import React from "react";
import MoveListItem from "../MoveListItem/MoveListItem.component";


const MoveListSection = ({sectionTitle, moveList, currentMoveId, sectionIdx}) => {
  return (
    <div className="section-container" key={sectionTitle}>
      <h2>{sectionTitle}</h2>
      {moveList.map((move, moveIdx) => (
        <MoveListItem
          key={move.id+moveIdx}
          isCurrentMove={move.id === currentMoveId}
          movePositionId={{ section: sectionIdx, move: moveIdx, video: 0 }}
          time={move.videos.reduce((acc, { startTime, endTime }) => {
            return acc + (endTime - startTime);
          }, 0)}
          {...move}
        />
      ))}
    </div>
  );
};

export default MoveListSection;
