import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import WorkoutActionTypes from "./workout/workout.types";
import persistReducer from "./root-reducer";
import thunk from "redux-thunk";

const middlewares = [thunk];

// Only use redux-logger in development
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    predicate: (getState, action) =>
      action.type !== WorkoutActionTypes.SET_CURRENT_TIME,
  });
  middlewares.push(logger);
}
// console.log(middlewares);
const store = createStore(persistReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default store;
