import React from "react";
import { useField } from "formik";
// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";

import "./CustomInput.styles.scss";
// const useStyles = makeStyles({
//   textField: {
//     backgroundColor: "#3d3d3d",
//     color: "#FFF",
//   },
// });

const CustomInput = ({ label, ...props }) => {
  // const classes = useStyles();
  const [field, meta] = useField(props);
  return (
    <>
      <label className="customInput-label">
        {label}
        <input className="customInput-input" {...field} {...props} />
      </label>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export default CustomInput;
/*



    <TextField className={classes.textField} variant="filled" label={label} {...field} {...props} error={meta.touched && meta.error} helperText={meta.error}/>

*/
