import React from 'react'

import "./CustomButton-gleam.styles.scss"

const CustomButton = ({children, isGoogleSignIn,   ...otherProps}) => {
  return (
    <button className={`${isGoogleSignIn ? "google":""} button`} {...otherProps}>
      {children}
    </button>
  )
}

export default CustomButton
